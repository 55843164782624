#welcome-area {
    display: flex;
    width: 100%;
    background-color: var(--grey-10);
    flex-grow: 1;
    background-image: url("../../../public/img/visualizer_screenshot_1.png");
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom: 3px solid #101010;
}

.welcome-text-container {
    display: flex;
    flex-flow: column;
    margin: auto;
}

#welcome-text {
    border: 3px solid var(--grey-40);
    border-radius: 20px;
    background-color: var(--grey-20-transparent);
    padding: 20px;
    display: flex;
    flex-flow: column;
    margin: 100px 20px;
    max-width: 500px;
}

#services-area {
    display: flex;
    width: 100%;
    background-color: var(--grey-20);
    flex-grow: 0;
    padding-bottom: 20px;
    padding-top: 20px;
}

.service-description {
    margin: 20px;
    max-width: 500px;
}