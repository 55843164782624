.about-us-text-area {
    border-radius: 20px;
    border: 3px solid var(--grey-40);
    background-color: var(--grey-20-transparent);
    padding: 20px;
    margin: 20px 40px;
}

.about-us-text {
    margin: 20px;
    max-width: 700px;
}

.page-contact {
    background-image: url("../../../public/img/visualizer_screenshot_3.png");
    background-repeat: no-repeat;
    background-size: cover;
    flex-grow: 1;
}

.send-button {
    margin-left: auto;
    margin-top: 8px;
    padding: 4px 8px;
}