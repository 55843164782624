html, body, #root, .App {
    height: 100%;

    --primary-10: #3DEB79;
    --primary-20: #516B5A;
    --secondary-10: #EBA63D;
    --secondary-15: #96621E;
    --secondary-10-transparent: #EBA63D95;
    --secondary-20: #785996;
    --grey-10: #E0E0E0;
    --grey-20: #A0A0A0;
    --grey-20-transparent: #A0A0A0E0;
    --grey-30: #707070;
    --grey-30-transparent: #707070E0;
    --grey-40: #404040;
    --grey-50: #202020;
    --grey-60: #101010;

    --header-height: 80px;
}

/* Common Components */
li {
    margin-left: 40px
}

/* Utilities for flex-flow */
.page {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.flex {
    display: flex;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.flex-right {
    margin-left: auto;
}

.flex-grow {
    flex-grow: 1;
}

.center {
    margin: auto;
}

.center-vertical {
    margin-bottom: auto;
    margin-top: auto;
}

.center-horizontal {
    margin-left: auto;
    margin-right: auto;
}

.opaque {
    opacity: 1!important;
}

/* Containers */
.container-1 {
    display: flex;
    flex-direction: column;
    background-color: var(--grey-10);
    border: 2px solid var(--grey-40);
    border-radius: 10px;
    margin: 20px;
    padding: 20px;
    max-width: 400px;
}

.filler {
    min-width: 10px;
    flex-grow: 1;
}

.scrollable {
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.margin-20 {
    margin: 20px;
}

.margin-l-20 {
    margin-left: 20px;
}

.margin-r-20 {
    margin-right: 20px;
}

.padding-r-20 {
    padding-right: 20px;
}

.padding-r-30 {
    padding-right: 30px;
}

button {
    border: 2px solid var(--grey-40);
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
}

label {
    font-weight: bold;
}

input {
    border: 2px solid var(--grey-40);
    border-radius: 10px;
    padding: 6px;
    margin-bottom: 10px;
}

textarea {
    border: 2px solid var(--grey-40);
    border-radius: 10px;
    padding: 10px;
    height: 200px;
}

.icon {
    font-size: 60px;
}

.img-grow {
    max-width: 100%;
    margin: 20px;
}