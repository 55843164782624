#header {
    /*min-width: var(--min-width);*/
    min-height: var(--header-height);
    background-color: var(--grey-30);
    border-bottom: 3px solid #101010;
    padding: 0 20px;
}

#page-buttons {
    display: flex;
    flex-direction: row;
    margin: auto 0 auto auto;
    min-width: 0;
}

.page-button {
    margin: auto 0 auto 30px;
    padding-bottom: 3px;
    cursor: pointer;
}

.page-button-selected {
    border-bottom: 3px solid var(--grey-40);
}