.page-software {
    display: flex;
    flex-direction: column;
    background-image: url("../../../public/img/visualizer_screenshot_3.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.software-text-area {
    background-color: rgba(255, 255, 255, 0.7);
    padding: 20px;
}

.github-container {
    max-width: 300px;
    border: 2px solid var(--grey-40);
    border-radius: 10px;
    padding: 20px;
    display: flex;
    background-color: var(--grey-10);
    flex-direction: column;
}