.page-lighting {
    background-image: url("../../../public/img/lighting_image_1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.lighting-text {
    margin: 20px;
    background-color: var(--secondary-10-transparent);
    padding: 20px;
    border-radius: 20px;
    border: 3px solid var(--secondary-15);
}

.video-link {
    margin: 20px auto 20px auto;
    padding: 10px;
}